<template>
  <div>
    <span class="note-text">Actions</span>
    <v-row>
      <v-col
        v-for="(r, i) in actionsList"
        :key="i"
        cols="12"
        sm="3"
      >
        <v-checkbox
          v-model="selected"
          :label="r.name"
          :value="r.id"
          :disabled="r.is_default"
          @change="onChangeOptions"
        />
        <div v-if="r.id == 1 && selected.includes(1)">
          <!-- <v-text-field
            v-model="emails"
            dense
            :label="$t('email_*')"
            :rules="[rules.required, validateEmails]"
            @change="onChangeEmail"
          /> -->
          <v-textarea
            v-model="emails"
            auto-grow
            dense
            :label="$t('email_*')"
            rows="1"
            row-height="15"
            :rules="[rules.required, validateEmails]"
            @change="onChangeEmail"
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { validationRules } from "@/mixins/validationRules";
import { mapGetters } from "vuex";
export default {
  mixins: [validationRules],
  props: {
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    type: {
      type: String,
      default() {
        return "";
      },
    },
    actionsList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      selected: [],
      emails: "",
    };
  },
  computed: {
    ...mapGetters({
      metaData: "alertsAndNotify/getMetaData",
    }),
  },

  watch: {
    value() {
      this.setData();
    },
  },
  mounted() {
    const act = this.actionsList.find((r) => r.is_default);
    this.selected.push(act.id);
  },
  updated() {
    const act = this.actionsList.find((r) => r.is_default);
    if (!this.selected.includes(act.id)) {
      this.selected.push(act.id);
    }
  },
  methods: {
    setData() {
      if (this.type === "edit") {
        this.selected = [];
        this.emails = "";
        this.value.forEach((el) => {
          this.selected.push(el.md_alert_action);
          if (el.md_alert_action == 1) {
            this.emails = el?.parameters.join(", ");
          }
        });
      }
    },
    onChangeOptions(v) {
      // if email unchecked remote email
      if (!v.includes(1)) {
        this.emails = "";
      }
      this.emitData();
    },
    onChangeEmail() {
      this.emitData();
    },
    emitData() {
      //   Sort selected
      this.selected.sort();

      const emails = this.emails.split(",");

      const params = this.selected.map((r) => ({
        md_alert_action: r,
        ...(r == 1 && { parameters: emails }),
      }));
      //   this.value == params;
      this.$emit("input", params);
    },

    //
    validateEmails(value) {
      const emails = value.split(",").map((email) => email.trim());
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      const uniqueEmails = new Set(); // Use a Set to store unique emails

      for (const email of emails) {
        if (email) {
          if (!emailRegex.test(email)) {
            return "Please enter valid email addresses separated by commas.";
          }
          if (uniqueEmails.has(email)) {
            return "Duplicate email addresses found.";
          }
          uniqueEmails.add(email); // Add valid email to the Set
        }
      }
      return true; // Return true if all emails are valid and unique
    },
  },
};
</script>
